import React, { Component } from 'react';
import {
  AppBar,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple, red } from '@mui/material/colors';
import NumberFormat from 'react-number-format';
import Logo from './Logo';
import './App.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme({
  palette: {
      primary: {
          main: '#ffffff'
      },
      secondary: {
          main: purple[500]
      }
  },
  typography: {
      fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 12,
      color: red
  }
});

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['Local', 'Up Country'];

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      heading1: 'FCL',
      heading2: 'LCL',
      heading3: 'Bulk',
      fclData: {
        labels,
        datasets: [
          {
            label: 'Added',
            data: [0, 0],
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Delivered',
            data: [0, 0],
            backgroundColor: 'rgb(75, 192, 192)',
          },
          {
            label: 'Cancelled',
            data: [0, 0],
            backgroundColor: 'rgb(75, 192, 192)',
          },
        ],
      },
      lclData: {
        labels,
        datasets: [
          {
            label: 'Arranged',
            data: [0, 0],
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Balance',
            data: [0, 0],
            backgroundColor: 'rgb(75, 192, 192)',
          },
        ],
      },
      bulkData: {
        labels,
        datasets: [
          {
            label: 'Arranged',
            data: [0, 0],
            backgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: 'Balance',
            data: [0, 0],
            backgroundColor: 'rgb(75, 192, 192)',
          },
        ],
      }
    };

    this.fetchData();
  }

  fetchData = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT+'/Job/ListPendingArrangmentJobs', {
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(results => { 
      return results.json()
    })
    .then(data => {
      this.setState({
        jobs: data
      });

      var fcl1 = 0;
      var fcl2 = 0;
      var fcl3 = 0;
      var fcl4 = 0;
      var lcl1 = 0;
      var lcl2 = 0;
      var lcl3 = 0;
      var lcl4 = 0;
      var bulk1 = 0;
      var bulk2 = 0;
      var bulk3 = 0;
      var bulk4 = 0;

      data.forEach(function (job) {
        if (job.ShipmentTypeName === 'FCL') {
          if (job.DeliveryType === 'Local') {
            fcl1 = fcl1 + job.VehiclesArranged;
            fcl2 = fcl2 + job.VehiclesBalance;
          } else {
            fcl3 = fcl3 + job.VehiclesArranged;
            fcl4 = fcl4 + job.VehiclesBalance;
          }
        } else {
          if (job.ShipmentTypeName === 'LCL') {
            if (job.DeliveryType === 'Local') {
              lcl1 = lcl1 + job.VehiclesArranged;
              lcl2 = lcl2 + job.VehiclesBalance;
            } else {
              lcl3 = lcl3 + job.VehiclesArranged;
              lcl4 = lcl4 + job.VehiclesBalance;
            }
          } else {
            if (job.ShipmentTypeName === 'Bulk') {
              if (job.DeliveryType === 'Local') {
                bulk1 = bulk1 + job.VehiclesArranged;
                bulk2 = bulk2 + job.VehiclesBalance;
              } else {
                bulk3 = bulk3 + job.VehiclesArranged;
                bulk4 = bulk4 + job.VehiclesBalance;
              }
            }
          }
        }
      });

      if (fcl1+fcl2+fcl3+fcl4 !== 0) {
        this.setState({
          heading1: 'FCL ( ' + (fcl2+fcl4) + ' / ' + (fcl1+fcl2+fcl3+fcl4) + ' )'
        });
      }
      if (lcl1+lcl2+lcl3+lcl4 !== 0) {
        this.setState({
          heading2: 'LCL ( ' + (lcl2+lcl4) + ' / ' + (lcl1+lcl2+lcl3+lcl4) + ' )'
        });
      }
      if (bulk1+bulk2+bulk3+bulk4 !== 0) {
        this.setState({
          heading3: 'Bulk ( ' + (bulk2+bulk4) + ' / ' + (bulk1+bulk2+bulk3+bulk4) + ' )'
        });
      }

      this.setState({
        fclData: {
          labels,
          datasets: [
            {
              label: 'Arranged',
              data: [fcl1, fcl3],
              backgroundColor: 'rgb(255, 99, 132)',
            },
            {
              label: 'Balance',
              data: [fcl2, fcl4],
              backgroundColor: 'rgb(75, 192, 192)',
            },
          ],
        },
        lclData: {
          labels,
          datasets: [
            {
              label: 'Arranged',
              data: [lcl1, lcl3],
              backgroundColor: 'rgb(255, 99, 132)',
            },
            {
              label: 'Balance',
              data: [lcl2, lcl4],
              backgroundColor: 'rgb(75, 192, 192)',
            },
          ],
        },
        bulkData: {
          labels,
          datasets: [
            {
              label: 'Arranged',
              data: [bulk1, bulk3],
              backgroundColor: 'rgb(255, 99, 132)',
            },
            {
              label: 'Balance',
              data: [bulk2, bulk4],
              backgroundColor: 'rgb(75, 192, 192)',
            },
          ],
        }
      });

      //setRecordCount(data.length);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  refreshData = setInterval(function() {
    this.fetchData();
  }, 60000);

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar
            position="absolute"
            color="primary"
            elevation={0}
            sx={{
                position: 'relative',
                borderBottom: (t) => `1px solid ${t.palette.divider}`,
            }}
        >
            <Toolbar>
              <Logo />
            </Toolbar>
        </AppBar>
        <Container component="main" maxWidth={false} sx={{ mb: 4 }}>
          <Grid
            container
            justify="space-between"
            xs={12}
            rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <Grid item xs={4}>
              <Paper variant="outlined" sx={{ my: { xs: 2, md: 2 }, p: { xs: 1, md: 1 } }}>
                <Typography component="h1" variant="h4" align="center">
                  {this.state.heading1}
                </Typography>
                <Bar options={options} data={this.state.fclData} />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper variant="outlined" sx={{ my: { xs: 2, md: 2 }, p: { xs: 1, md: 1 } }}>
                <Typography component="h1" variant="h4" align="center">
                  {this.state.heading2}
                </Typography>
                <Bar options={options} data={this.state.lclData} />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper variant="outlined" sx={{ my: { xs: 2, md: 2 }, p: { xs: 1, md: 1 } }}>
                <Typography component="h1" variant="h4" align="center">
                  {this.state.heading3}
                </Typography>
                <Bar options={options} data={this.state.bulkData} />
              </Paper>
            </Grid>
          </Grid>
          <Paper variant="outlined" sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '12%' }}>
                    Job No.
                    <hr />
                    Initiated On
                  </TableCell>
                  <TableCell>
                    Customer
                    <hr />
                    Consignee
                  </TableCell>
                  <TableCell>
                    Shipment Type
                    <hr />
                    Route
                  </TableCell>
                  <TableCell>
                    Origin
                    <hr />
                    Destination
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    Total Vehicles Required
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    Total Vehicles Arranged
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    Balance Vehicles
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.jobs.map((job) => (
                  <TableRow
                    hover
                    key={job.JobNumber}
                  >
                    <TableCell style={{ width: '10%' }}>
                      <Typography>{job.JobNumber}</Typography>
                      <hr />
                      <Typography>{job.JobDate}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{job.CustomerName}</Typography>
                      <hr />
                      <Typography>{job.ConsigneeName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{job.ShipmentTypeName}</Typography>
                      <hr />
                      <Typography>{job.DeliveryType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{job.Origin}</Typography>
                      <hr />
                      <Typography>{job.Destination}</Typography>
                    </TableCell>
                    <TableCell style={{ width: '10%' }}>
                      <Typography align="center">
                        <NumberFormat
                          value={job.VehiclesRequired}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: '10%' }}>
                      <Typography align="center">
                        <NumberFormat
                          value={job.VehiclesArranged}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell style={{ width: '10%' }}>
                      <Typography align="center">
                        <NumberFormat
                            value={job.VehiclesBalance}
                            displayType={"text"}
                            thousandSeparator={true}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Container>
      </ThemeProvider>
    );
  }
}


